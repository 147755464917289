/** lemonway */
export const LEMONWAY_EXTERNAL_STATUSES = {
  kyc1: 'kyc_1',
  kyc2: 'kyc_2',
  closed: 'closed',
  kycIncomplete: 'kyc_incomplete',
  rejected: 'rejected',
  blocked: 'blocked',
  expired: 'expired',
}

export const LEMONWAY_INTERNAL_STATUSES = {
  change: 'change',
  inReview: 'in_review',
  reviewed: 'reviewed',
  init: 'init',
  requested: 'requested',
}

export const LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES = {
  accepted: 'accepted',
  expired: 'expired',
  notAccepted: 'not_accepted',
  notVerified: 'not_verified',
  onHold: 'on_hold',
  sent: 'sent',
  unreadable: 'unreadable',
  wrongName: 'wrong_name',
  wrongType: 'wrong_type',
}

export const LEMONWAY_LEGITIMATION_REQUIRED_FILE_TYPES = [
  'id_from_front',
  'id_from_back',
  'proof_doc',
  'company_lr',
  'article_doc',
]
export const LEMONWAY_LEGITIMATION_FILE_TYPES = [
  ...LEMONWAY_LEGITIMATION_REQUIRED_FILE_TYPES,
  'other_doc_1',
  'other_doc_2',
]

/** mangopay */
export const MANGOPAY_INTERNAL_STATUSES = {
  headQuarter: 'head_quarter',
  legalRepresentative: 'legal_representative',
  documents: 'documents',
  ubo: 'ubo',
  requested: 'requested',
  inReview: 'in_review',
  change: 'change',
  registered: 'registered',
  legitimated: 'legitimated',
  rejected: 'rejected',
}

export const MANGOPAY_EXTERNAL_STATUSES = {
  light: 'light',
  regular: 'regular',
}

export const MANGOPAY_COMPANY_FORMS = {
  soleTrader: 'sole_trader',
  business: 'business',
  organization: 'organization',
}
export const MANGOPAY_COMPANY_FORMS_LIST = Object.values(MANGOPAY_COMPANY_FORMS)

export const MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES = {
  created: 'created',
  init: 'init',
  refused: 'refused',
  validated: 'validated',
  validationAsked: 'validation_asked',
}

export const MANGOPAY_LEGITIMATION_SOLE_TRADER_DOCUMENTS = ['id_verification', 'company_lr']
export const MANGOPAY_LEGITIMATION_ORGANIZATION_DOCUMENTS = [
  ...MANGOPAY_LEGITIMATION_SOLE_TRADER_DOCUMENTS,
  'other_doc_1',
]
export const MANGOPAY_LEGITIMATION_DOCUMENTS = [
  ...MANGOPAY_LEGITIMATION_ORGANIZATION_DOCUMENTS,
  'shareholder_declaration',
]

/** common */

export const UBO_DECLARATION_STATUSES = {
  ...MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES,
  incomplete: 'incomplete',
}

export const PAYMENT_FORMS = {
  paypal: 'paypal',
  card: 'card',
  sofort: 'sofort',
  bankWire: 'bank_wire',
  sepa: 'sepa',
  payLater: 'pay_later',
  p24: 'p_24',
  applePay: 'apple_pay',
  googlePay: 'google_pay',
  ideal: 'ideal',
  klarna: 'klarna',
  twint: 'twint',
}
export const getPaymentFormsLabels = () => ({
  apple_pay: I18n.t('shared.payment_form.apple_pay'),
  bank_account: I18n.t('shared.payment_form.bank_account'),
  bank_wire: I18n.t('shared.payment_form.bank_wire'),
  card: I18n.t('shared.payment_form.card'),
  chargebacked: I18n.t('shared.payment_form.chargebacked'),
  credit_card: I18n.t('shared.payment_form.credit_card'),
  digital_card: I18n.t('shared.payment_form.digital_card'),
  direct_debit_uk: I18n.t('shared.payment_form.direct_debit_uk'),
  ext_balance: I18n.t('shared.payment_form.ext_balance'),
  free: I18n.t('shared.payment_form.free'),
  google_pay: I18n.t('shared.payment_form.google_pay'),
  iap_google_apple_pay: I18n.t('shared.payment_form.iap_google_apple_pay'),
  not_assigned: I18n.t('shared.payment_form.not_assigned'),
  p24: I18n.t('shared.payment_form.p24'),
  p_24: I18n.t('shared.payment_form.p_24'),
  partly_paid: I18n.t('shared.payment_form.partly_paid'),
  pay_later: I18n.t('shared.payment_form.pay_later'),
  paypal: I18n.t('shared.payment_form.paypal'),
  sepa: I18n.t('shared.payment_form.sepa'),
  sofort: I18n.t('shared.payment_form.sofort'),
  ideal: I18n.t('shared.payment_form.ideal'),
  klarna: I18n.t('shared.payment_form.klarna'),
  twint: I18n.t('shared.payment_form.twint'),
})

export const PAYMENT_PROVIDERS_LABELS = {
  direct_bank: I18n.t('react.cabinet.payment_methods.payment_providers.direct_bank'),
  elopage_connect: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
  in_app_purchase: I18n.t('react.cabinet.payment_methods.payment_providers.in_app_purchase'),
  lemonway: I18n.t('react.cabinet.payment_methods.payment_providers.lemonway'),
  mango_pay: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
  no_provider: I18n.t('react.cabinet.payment_methods.payment_providers.no_provider'),
  paypal_nvp: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_nvp'),
  paypal_rest: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_rest'),
  stripe: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
}

export const STRIPE_ELEMENT_ROOT_ID = 'stripe-bank-element'

export const PAYMENT_PROVIDERS = {
  lemonWay: 'lemonway',
  mangoPay: 'mango_pay',
  elopageConnect: 'elopage_connect',
  stripe: 'stripe',
}

export const WRONG_VAT = 'wrong vat'

export const getPaymentPeriodLabels = () => ({
  installment: I18n.t('shared.payment_period.installment'),
  limited_subscription: I18n.t('shared.payment_period.limited_subscription'),
  one_time: I18n.t('shared.payment_period.one_time'),
  subscription: I18n.t('shared.payment_period.subscription'),
})
export const getPaymentStateLabels = () => ({
  active: I18n.t('shared.payment_state.active'),
  canceled: I18n.t('shared.payment_state.canceled'),
  chargeback: I18n.t('shared.payment_state.chargeback'),
  chargebacked: I18n.t('shared.payment_state.chargebacked'),
  debt: I18n.t('shared.payment_state.debt'),
  error: I18n.t('shared.payment_state.error'),
  expired: I18n.t('shared.payment_state.expired'),
  future: I18n.t('shared.payment_state.future'),
  out_of_duration: I18n.t('shared.payment_state.out_of_duration'),
  paid: I18n.t('shared.payment_state.paid'),
  partly_paid: I18n.t('shared.payment_state.partly_paid'),
  payed: I18n.t('shared.payment_state.payed'),
  paying: I18n.t('shared.payment_state.paying'),
  payment_canceled: I18n.t('shared.payment_state.payment_canceled'),
  payment_chargebacked: I18n.t('shared.payment_state.payment_chargebacked'),
  payment_not_completed: I18n.t('shared.payment_state.payment_not_completed'),
  payment_paused: I18n.t('shared.payment_state.payment_paused'),
  payment_pending: I18n.t('shared.payment_state.payment_pending'),
  payment_refunded: I18n.t('shared.payment_state.payment_refunded'),
  payment_to_be_paused: I18n.t('shared.payment_state.payment_to_be_paused'),
  payment_waiting: I18n.t('shared.payment_state.payment_waiting'),
  pending: I18n.t('shared.payment_state.pending'),
  skipped: I18n.t('shared.payment_state.skipped'),
  refund: I18n.t('shared.payment_state.refund'),
  refunded: I18n.t('shared.payment_state.refunded'),
  reminded: I18n.t('shared.payment_state.reminded'),
  reversed: I18n.t('shared.payment_state.reversed'),
  to_be_paid: I18n.t('shared.payment_state.to_be_paid'),
  unpaid: I18n.t('shared.payment_state.unpaid'),
  issued: I18n.t('shared.payment_state.issued'),
})

export const INVOICE_FORM_LABELS = {
  regular: I18n.t('shared.invoice_form.regular'),
  reversal: I18n.t('shared.invoice_form.reversal'),
  corrected: I18n.t('shared.invoice_form.corrected'),
}
export const PAYMENT_TYPE_LABELS = {
  cashin: I18n.t('react.shared.payment_type.cashin'),
  cashout: I18n.t('react.shared.payment_type.cashout'),
  cashouts: I18n.t('react.shared.payment_type.cashouts'),
  chargeback: I18n.t('react.shared.payment_type.chargeback'),
  chargebacks: I18n.t('react.shared.payment_type.chargebacks'),
  payment: I18n.t('react.shared.payment_type.payment'),
  payment_refunds: I18n.t('react.shared.payment_type.payment_refunds'),
  payments: I18n.t('react.shared.payment_type.payments'),
  payout: I18n.t('react.shared.payment_type.payout'),
  refund: I18n.t('react.shared.payment_type.refund'),
  subspayment: I18n.t('react.shared.payment_type.subspayment'),
}

export const getPaymentTypeLabels = () => ({
  cashin: I18n.t('react.shared.payment_type.cashin'),
  cashout: I18n.t('react.shared.payment_type.cashout'),
  cashouts: I18n.t('react.shared.payment_type.cashouts'),
  chargeback: I18n.t('react.shared.payment_type.chargeback'),
  chargebacks: I18n.t('react.shared.payment_type.chargebacks'),
  payment: I18n.t('react.shared.payment_type.payment'),
  payment_refunds: I18n.t('react.shared.payment_type.payment_refunds'),
  payments: I18n.t('react.shared.payment_type.payments'),
  payout: I18n.t('react.shared.payment_type.payout'),
  refund: I18n.t('react.shared.payment_type.refund'),
  subspayment: I18n.t('react.shared.payment_type.subspayment'),
})

export const DUNNING_STATUS_LABELS = {
  1: I18n.t('react.cabinet.dunning.status.1'),
  2: I18n.t('react.cabinet.dunning.status.2'),
  3: I18n.t('react.cabinet.dunning.status.3'),
  collected: I18n.t('react.cabinet.dunning.status.collected'),
  completed: I18n.t('react.cabinet.dunning.status.completed'),
  court: I18n.t('react.cabinet.dunning.status.court'),
  in_inkasso: I18n.t('react.cabinet.dunning.status.in_inkasso'),
  inkasso_collected: I18n.t('react.cabinet.dunning.status.inkasso_collected'),
  inkasso_not_collected: I18n.t('react.cabinet.dunning.status.inkasso_not_collected'),
  inkasso_status: I18n.t('react.cabinet.dunning.status.inkasso_status'),
  in_progress: I18n.t('react.cabinet.dunning.status.in_progress'),
  manually_resolved_dunning: I18n.t('react.cabinet.dunning.status.manually_resolved_dunning'),
  manually_resolved_inkasso: I18n.t('react.cabinet.dunning.status.manually_resolved_inkasso'),
  no: I18n.t('react.cabinet.dunning.status.no'),
  not_collected: I18n.t('react.cabinet.dunning.status.not_collected'),
  not_ready_for_inkasso: I18n.t('react.cabinet.dunning.status.not_ready_for_inkasso'),
  outcome_status: I18n.t('react.cabinet.dunning.status.outcome_status'),
  paid: I18n.t('react.cabinet.dunning.status.paid'),
  partially_collected: I18n.t('react.cabinet.dunning.status.partially_collected'),
  post_court: I18n.t('react.cabinet.dunning.status.post_court'),
  pre_court: I18n.t('react.cabinet.dunning.status.pre_court'),
  ready_for_inkasso: I18n.t('react.cabinet.dunning.status.ready_for_inkasso'),
  send_to_dca: I18n.t('react.cabinet.dunning.status.send_to_dca'),
  skipped: I18n.t('react.cabinet.dunning.status.skipped'),
  stop_blocked: I18n.t('react.cabinet.dunning.status.stop_blocked'),
}
export const getDunningStageLabels = (count) => ({
  1: I18n.t('react.cabinet.dunning.status.stage_1', { count }),
  2: I18n.t('react.cabinet.dunning.status.stage_2', { count }),
  3: I18n.t('react.cabinet.dunning.status.stage_3', { count }),
  court: I18n.t('react.cabinet.dunning.status.stage_court'),
  in_inkasso: I18n.t('react.cabinet.dunning.status.stage_in_inkasso'),
  post_court: I18n.t('react.cabinet.dunning.status.stage_post_court'),
  pre_court: I18n.t('react.cabinet.dunning.status.stage_pre_court'),
  ready_for_inkasso: I18n.t('react.cabinet.dunning.status.stage_ready_for_inkasso'),
})

export const DUNNING_STRATEGY_LABELS = {
  dunning: I18n.t('react.cabinet.dunning.shared.strategy.dunning'),
  dunning_inkasso: I18n.t('react.cabinet.dunning.shared.strategy.dunning_inkasso'),
  inkasso: I18n.t('react.cabinet.dunning.shared.strategy.inkasso'),
}
