import I18n from '@elo-kit/components/i18n'

export const parseShrinkDate = (period = '') => {
  const [number, letter] = (period || '').split(/(\d+)/).filter(Boolean)

  return {
    number,
    letter,
  }
}

export const getTimePeriodLocale = (interval) => {
  switch (interval) {
    case '1d':
      return I18n.t('react.shared.checkout.interval.per_day')
    case '1w':
      return I18n.t('react.shared.checkout.interval.per_week')
    case '1m':
      return I18n.t('react.shared.checkout.interval.per_month')
    case '1y':
      return I18n.t('react.shared.checkout.interval.per_year')
    default: {
      const { number, letter } = parseShrinkDate(interval)

      return {
        d: I18n.t('react.shared.checkout.interval.every_amount_days', { amount: number }),
        w: I18n.t('react.shared.checkout.interval.every_amount_weeks', { amount: number }),
        m: I18n.t('react.shared.checkout.interval.every_amount_months', { amount: number }),
        y: I18n.t('react.shared.checkout.interval.every_amount_years', { amount: number }),
      }[letter]
    }
  }
}
