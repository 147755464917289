import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import { uuid } from 'utils/uuid'
import classNames from 'classnames'
import moment from 'moment'

import { EloCard } from '@elo-ui/components/elo-card/elo-card'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display/elo-price-display'
import { EloStatusBadge } from '@elo-ui/components/elo-status-badge/elo-status-badge'
import { EloStarUnselectedIcon } from '@elo-ui/components/icons/regular/star-unselected-icon/star-unselected-icon'
import { EloCheckCircleIcon } from '@elo-ui/components/icons/regular/check-circle-icon/check-circle-icon'
import { EloRadioButton } from '@elo-ui/components/elo-radio-button/elo-radio-button'
import { EloPaymentPlan } from '@elo-ui/components/elo-payment-plan/elo-payment-plan'
import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button/elo-button'

import { totalPlanAmount } from '@elo-kit/utils/product.utils'
import { getPricingPlanName, getPricingPlanDescription } from '@elo-kit/utils/pricingPlans.utils'
import { checkIsCouponApplied } from '@elo-kit/utils/coupons.utils'
import { concatParsedShrinkDate, formatDateTime } from '@elo-kit/utils/dateTime.utils'
import { createFormattedDate, parseShrinkDate } from 'utils/dateTime.utils'
import { profile } from 'utils/profileHelper.utils'
import { convertToPercents } from 'utils/helpers.utils'
import { getTimePeriodLocale } from 'shop/utils/dateTime.utils'

import { PAYMENT_PLANS } from '@elo-kit/constants/pricingPlans.constants'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { Coupon } from '@elo-kit/constants/coupons.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { VALUE_BEFORE_FIRST_DOT_REGEX } from 'constants/regex.constants'
import { SELECT_INTERVAL_OPTIONS } from 'constants/orders.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import { Countdown } from './countdown/Countdown'

import './payment-plan-modal.scss'

interface PaymentItem {
  variant: 'trial' | 'first' | 'default'
  paymentName: string
  price: number
}

interface Prefs {
  price?: string
  oldPrice?: string
  firstInterval?: string
  firstAmount?: number
  oldFirstAmount?: number
  rate1Amount?: string
  testPeriod?: string | undefined
  nextInterval?: string
  nextAmount?: number
  paymentsCount?: number
  customIntervals?: boolean
  minimumTermInitialPeriod?: number
  minimumTermNextPeriod?: number
  minimumTerms?: boolean
  absoluteDates?: boolean
  rate1Date?: string
  customStartDay?: string
}

interface PricingPlan {
  id?: number
  name?: string
  description?: string
  form?: string
  currencyId?: number
  currencyKey?: number
  firstIntervalDate?: string
  nextIntervalDate?: string
  useNetPrice?: boolean
  prefs?: Prefs
  testPeriodEnabled?: boolean
  recommended?: boolean
  activateCountdown?: boolean
  validTill?: string
}

interface AmountCrossedArg {
  amount: string | number
  isCrossed: boolean
}

interface PricingPlanListItemProps {
  pricingPlan?: PricingPlan
  coupon?: Coupon
  forForm?: boolean
  keepEnabled?: boolean
  checked?: boolean
  onSelectPlan?: (planId: number) => void
  enabledPlans?: Record<string, boolean>
  togglePlan?: (planId: number) => void
  showEnablingPlans?: boolean
  ticketId?: number
  minTerm?: boolean
  convertToPrice?: (
    value: string | number | undefined,
    currencyId?: number | string,
    separator?: string
  ) => string | number // currencyStore.convertToPric
  amountCrossed?: (object: AmountCrossedArg) => string | number // helpers.util
  isWindows?: boolean
  paymentPageColor?: string
  paymentCountdownBgColor?: string
  paymentCountdownBorderColor?: string
  paymentCountdownTextColor?: string
  paymentCountdownTimeColor?: string
  countdownText?: string
  customDay?: number
  withoutNet?: boolean
  numberOfPlans?: number
  paymentRecommendedBadgeColor?: string
  paymentRecommendedBadgeTextColor?: string
  layout?: string
  showNewPricingPlanContent?: boolean
}

interface NewPricingPlanContent {
  pricingPlan: PricingPlan
  forForm: boolean
  keepEnabled: boolean
  checked: boolean
  onSelectPlan: (planId: number) => void
  enabledPlans: Record<string, boolean>
  togglePlan: (planId: number) => void
  showEnablingPlans: boolean
  minTerm: boolean
  convertToPrice: (
    value: string | number | undefined,
    currencyId?: number | string,
    separator?: string
  ) => string | number // currencyStore.convertToPric
  paymentPageColor: string
  paymentCountdownBgColor: string
  paymentCountdownBorderColor: string
  paymentCountdownTextColor: string
  paymentCountdownTimeColor: string
  countdownText: string
  withoutNet: boolean
  numberOfPlans: number
  paymentRecommendedBadgeColor: string
  paymentRecommendedBadgeTextColor: string
  layout: string
  formattedFirstIntervalDate: string
  minimumTermInitialPeriod: number
  minimumTermNextPeriod: number
  coupon: Coupon
  ticketId: number
  amountCrossed: (data: { amount: string | number; isCrossed: boolean }) => string | number
  showNewPricingPlanContent: boolean
}

const NO_DATA = '...'
const COUPON_COLOR = '#17df87'

//TODO: replace moment with dayjs
const NewPricingPlanContent: React.FC<NewPricingPlanContent> = observer((props) => {
  const {
    checked,
    forForm,
    paymentPageColor,
    onSelectPlan,
    pricingPlan,
    showEnablingPlans,
    enabledPlans,
    togglePlan,
    keepEnabled,
    paymentRecommendedBadgeColor,
    paymentRecommendedBadgeTextColor,
    convertToPrice,
    withoutNet,
    layout,
    numberOfPlans,
    formattedFirstIntervalDate,
    minTerm,
    minimumTermInitialPeriod,
    minimumTermNextPeriod,
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
    coupon,
    ticketId,
    amountCrossed,
    showNewPricingPlanContent,
  } = props
  const {
    id,
    name,
    description,
    form,
    currencyId,
    currencyKey,
    useNetPrice,
    prefs = {},
    testPeriodEnabled,
    recommended,
    activateCountdown,
    validTill,
    nextIntervalDate,
  } = pricingPlan
  const {
    price,
    oldPrice,
    firstInterval,
    firstAmount,
    oldFirstAmount,
    rate1Amount,
    testPeriod,
    nextInterval,
    nextAmount,
    paymentsCount,
    customIntervals,
    absoluteDates,
    rate1Date,
  } = prefs

  const I18n = useI18n()
  const [showFullPaymentPlanModal, setShowFullPaymentPlanModal] = useState(false)

  const isShopProfile = profile.profileType === ACTIVE_PROFILES.shop

  const showTestPeriod = Number(testPeriod) > 0
  const currency = currencyId || currencyKey
  const displayPrice = customIntervals ? rate1Amount || 0 : price || firstAmount || 0
  const displayOldPrice = oldPrice || oldFirstAmount
  const totalAmount = totalPlanAmount(pricingPlan)
  const displayRecommendedBadge = layout && recommended && numberOfPlans > 1
  const radioButtonDisabled = showEnablingPlans ? !(id && enabledPlans && enabledPlans[id]) : !forForm && !keepEnabled
  const isFormOneTime = form === PAYMENT_PLANS.oneTime
  const totalPrice = convertToPrice && convertToPrice(totalAmount, currency)
  const nextAmountText = nextAmount ? convertToPrice(nextAmount, currency) : ''

  const isCouponApplied = checkIsCouponApplied(coupon, pricingPlan.id, ticketId)
  const showCouponData = isCouponApplied && coupon?.data?.value
  const isRecurring = coupon?.data?.recurring
  const formattedPrice = convertToPrice(displayPrice, currency)
  const formattedOldPrice =
    Number(!!parseFloat(String(displayOldPrice || 0))) > 0 && convertToPrice(displayOldPrice, currency)
  const pricingPlanName = getPricingPlanName({ form, name, pricingPlan })
  const netText = useNetPrice && !withoutNet && I18n.t('react.shared.tax')

  const isInstallment = form === PAYMENT_PLANS.installment
  const isSubscription = form === PAYMENT_PLANS.subscription
  const isLimitedSubscription = form === PAYMENT_PLANS.limitedSubscription

  const { number: firstNumber, letter: firstLetter } = parseShrinkDate(firstInterval)
  const { number: nextNumber, letter: nextLetter } = parseShrinkDate(nextInterval)

  const calculatePriceWithCoupon = (oldPrice, coupon, isPercents) => {
    const price = Number(oldPrice)
    const discount = Number(coupon)

    if (!price ?? !discount) {
      return oldPrice
    } else if (isPercents) {
      return price - (price / 100) * discount
    } else {
      return discount > price ? 0 : price - discount
    }
  }

  const toggleFullPaymentPlanModal = () => setShowFullPaymentPlanModal(!showFullPaymentPlanModal)

  const getNextCustomIntervalDate = () =>
    Array<number>(paymentsCount - 1)
      .fill(0)
      .reduce((prevItem, item, index) => {
        const { number: nextCustomNumber, letter: nextCustomLetter } = parseShrinkDate(
          prefs[`rate${index + 1}Interval`]
        )

        return {
          ...prevItem,
          [index + 1]: moment(prevItem[index] || formattedFirstIntervalDate, 'DD.MM.YYYY')
            .add(Number(nextCustomNumber), SELECT_INTERVAL_OPTIONS[nextCustomLetter])
            .format('DD.MM.YYYY'),
        }
      }, {})

  const getPaymentItems = () => [
    ...(showTestPeriod && testPeriodEnabled
      ? [
          {
            variant: 'trial',
            paymentName: I18n.t('react.shared.pricing_plan.try_for_free', { count: testPeriod }),
            price: convertToPrice(0, currency),
            thinItem: true,
          } as PaymentItem,
        ]
      : []),
    {
      variant: 'first',
      paymentName: I18n.t('react.shared.pricing_plan.first_payment'),
      price: showCouponData
        ? convertToPrice(calculatePriceWithCoupon(displayPrice, coupon.data.value, coupon.data.percents), currency)
        : formattedPrice,
      oldPrice: showCouponData ? formattedPrice : formattedOldPrice,
      netPrice: netText,
      on:
        absoluteDates && rate1Date && isInstallment
          ? createFormattedDate(new Date(rate1Date))
          : formattedFirstIntervalDate,
      onLabel: I18n.t('react.shared.on'),
      thinItem: isLimitedSubscription && firstAmount !== nextAmount && !customIntervals,
    } as PaymentItem,
    ...(paymentsCount &&
      Array<number>(paymentsCount - 1)
        .fill(0)
        .map((item, index) => {
          const firstDate =
            formattedFirstIntervalDate === NO_DATA
              ? NO_DATA
              : moment(formattedFirstIntervalDate, 'DD.MM.YYYY')
                  .add(Number(firstNumber), SELECT_INTERVAL_OPTIONS[firstLetter])
                  .format('DD.MM.YYYY')

          let date = index
            ? moment(firstDate, 'DD.MM.YYYY')
                .add(index * Number(nextNumber), SELECT_INTERVAL_OPTIONS[nextLetter])
                .format('DD.MM.YYYY')
            : firstDate

          if (customIntervals) {
            date = getNextCustomIntervalDate()[index + 1]
          }

          if (absoluteDates && isInstallment) {
            date = formatDateTime(prefs[`rate${index + 2}Date`], DATE_FORMATS.DDMMYYYY)
          }

          const isLastIndex = paymentsCount - 2 === index

          if (paymentsCount > 2 && !customIntervals) {
            if (isLastIndex) {
              return {
                variant: 'default',
                paymentName: isLimitedSubscription
                  ? I18n.t('react.shared.pricing_plan.recurring_payments')
                  : I18n.t('react.shared.pricing_plan.recurring_installments'),
                price:
                  showCouponData && isRecurring
                    ? convertToPrice(
                        calculatePriceWithCoupon(nextAmount, coupon.data.value, coupon.data.percents),
                        currency
                      )
                    : nextAmountText,
                oldPrice: showCouponData && isRecurring ? nextAmountText : null,
                netPrice: netText,
                from: firstDate,
                fromLabel: I18n.t('react.shared.from_date'),
                to: firstDate === NO_DATA ? NO_DATA : date,
                toLabel: I18n.t('react.shared.to_date'),
                supportiveText: getTimePeriodLocale(nextInterval),
              } as PaymentItem
            } else {
              return
            }
          }

          const limitedSubscriptionLabel = !customIntervals
            ? I18n.t('react.shared.pricing_plan.second_payment')
            : I18n.t('react.shared.payment_type.payment')

          const priceWithoutCoupon = customIntervals
            ? convertToPrice(parseFloat(prefs[`rate${index + 2}Amount`] || ''), currency)
            : nextAmountText

          let priceWithCoupon
          if (showCouponData && isRecurring) {
            priceWithCoupon = customIntervals
              ? convertToPrice(
                  calculatePriceWithCoupon(
                    parseFloat(prefs[`rate${index + 2}Amount`]),
                    coupon.data.value,
                    coupon.data.percents
                  ),
                  currency
                )
              : convertToPrice(calculatePriceWithCoupon(nextAmount, coupon.data.value, coupon.data.percents), currency)
          }

          return {
            variant: 'default',
            paymentName: isLimitedSubscription ? limitedSubscriptionLabel : pricingPlanName,
            price: showCouponData && isRecurring ? priceWithCoupon : priceWithoutCoupon,
            oldPrice: showCouponData && isRecurring ? priceWithoutCoupon : null,
            netPrice: netText,
            on: date,
            onLabel: I18n.t('react.shared.on'),
          } as PaymentItem
        })
        .filter(Boolean)),
  ]

  const getSubscriptionPaymentItems = () => [
    ...(showTestPeriod && testPeriodEnabled
      ? [
          {
            variant: 'trial',
            paymentName: I18n.t('react.shared.pricing_plan.try_for_free', { count: testPeriod }),
            price: convertToPrice(0, currency),
            thinItem: true,
          } as PaymentItem,
        ]
      : []),
    {
      variant: 'first',
      paymentName: I18n.t('react.shared.pricing_plan.first_payment'),
      price: showCouponData
        ? convertToPrice(calculatePriceWithCoupon(firstAmount, coupon.data.value, coupon.data.percents), currency)
        : convertToPrice(firstAmount, currency),
      oldPrice: showCouponData ? convertToPrice(firstAmount, currency) : null,
      netPrice: netText,
      on: formattedFirstIntervalDate,
      onLabel: I18n.t('react.shared.on'),
      thinItem: true,
    } as PaymentItem,
    {
      variant: 'default',
      paymentName: I18n.t('react.shared.pricing_plan.recurring_payments'),
      price:
        showCouponData && isRecurring
          ? convertToPrice(calculatePriceWithCoupon(nextAmount, coupon.data.value, coupon.data.percents), currency)
          : convertToPrice(nextAmount, currency),
      oldPrice: showCouponData && isRecurring ? convertToPrice(nextAmount, currency) : null,
      netPrice: netText,
      from:
        formattedFirstIntervalDate === NO_DATA
          ? NO_DATA
          : moment(formattedFirstIntervalDate, 'DD.MM.YYYY')
              .add(Number(firstNumber), SELECT_INTERVAL_OPTIONS[firstLetter])
              .format('DD.MM.YYYY'),
      fromLabel: I18n.t('react.shared.subscription_from'),
    } as PaymentItem,
  ]

  const minimumTermInfo = (
    <div>
      <span>
        {I18n.t('react.shared.pricing_plan.min_term_init', {
          minimum_period:
            (parseInt(String(minimumTermInitialPeriod), 10) &&
              concatParsedShrinkDate(String(minimumTermInitialPeriod), true)) ||
            NO_DATA,
        })}
      </span>
      {!!parseInt(String(minimumTermNextPeriod), 10) && (
        <span>
          {' '}
          {I18n.t('react.shared.pricing_plan.min_term_extension', {
            extension_period: concatParsedShrinkDate(String(minimumTermNextPeriod)) || NO_DATA,
          })}
        </span>
      )}
    </div>
  )

  let modalPaymentItems
  let priceSupportiveText
  let priceToShow
  let oldPriceToShow

  if (isInstallment) {
    let price
    if (showCouponData && isRecurring && showNewPricingPlanContent) {
      let discount = 0

      if (customIntervals) {
        for (let i = 0; i < paymentsCount; i++) {
          const rate = parseFloat(prefs[`rate${i + 1}Amount`] || 0)
          const rateDiscount = rate - calculatePriceWithCoupon(rate, coupon.data.value, coupon.data.percents)

          discount += rateDiscount
        }
      } else {
        const firstAmountDiscount =
          firstAmount - calculatePriceWithCoupon(firstAmount, coupon.data.value, coupon.data.percents)
        const nextAmountDiscount =
          nextAmount - calculatePriceWithCoupon(nextAmount, coupon.data.value, coupon.data.percents)
        discount = firstAmountDiscount + nextAmountDiscount * (paymentsCount - 1)
      }

      price = convertToPrice(totalAmount - discount, currency)
    } else if (showCouponData && !isRecurring && showNewPricingPlanContent) {
      const discountPrice = calculatePriceWithCoupon(displayPrice, coupon.data.value, coupon.data.percents)
      const discount = Number(displayPrice) - discountPrice
      price = convertToPrice(totalAmount - discount, currency)
    } else if (!showNewPricingPlanContent) {
      const amount = !customIntervals ? firstAmount : rate1Amount
      price = showCouponData
        ? convertToPrice(calculatePriceWithCoupon(amount, coupon.data.value, coupon.data.percents), currency)
        : convertToPrice(amount, currency)
    } else {
      price = totalPrice
    }

    const priceWithCoupon = showNewPricingPlanContent
      ? totalPrice
      : convertToPrice(!customIntervals ? firstAmount : rate1Amount, currency)

    priceToShow = price
    oldPriceToShow = showCouponData ? priceWithCoupon : null
    priceSupportiveText = I18n.t('react.shared.pricing_plan.over_payments', { paymentsCount })
    modalPaymentItems = getPaymentItems()
  } else if (isSubscription) {
    if (showCouponData && showNewPricingPlanContent && isRecurring) {
      priceToShow = convertToPrice(
        calculatePriceWithCoupon(nextAmount, coupon.data.value, coupon.data.percents),
        currency
      )
      oldPriceToShow = nextAmountText || formattedPrice
    } else if (showCouponData && !showNewPricingPlanContent) {
      priceToShow = convertToPrice(
        calculatePriceWithCoupon(firstAmount, coupon.data.value, coupon.data.percents),
        currency
      )
      oldPriceToShow = formattedPrice
    } else {
      priceToShow = showNewPricingPlanContent ? nextAmountText || formattedPrice : formattedPrice
    }

    priceSupportiveText = getTimePeriodLocale(nextInterval)
    modalPaymentItems = getSubscriptionPaymentItems()
  } else if (isLimitedSubscription) {
    let limitedSubscriptionPrice = convertToPrice(totalAmount, currency)

    if (!showNewPricingPlanContent) {
      limitedSubscriptionPrice = formattedPrice
    }

    let limitedSubscriptionPriceWithCoupon

    if (showCouponData) {
      if (isRecurring) {
        let limitedSubscriptionDiscount = 0

        if (customIntervals) {
          for (let i = 0; i < paymentsCount; i++) {
            const rate = parseFloat(prefs[`rate${i + 1}Amount`] || 0)
            const rateDiscount = rate - calculatePriceWithCoupon(rate, coupon.data.value, coupon.data.percents)

            limitedSubscriptionDiscount += rateDiscount
          }
        } else {
          const firstAmountDiscount =
            firstAmount - calculatePriceWithCoupon(firstAmount, coupon.data.value, coupon.data.percents)
          const nextAmountDiscount =
            nextAmount - calculatePriceWithCoupon(nextAmount, coupon.data.value, coupon.data.percents)
          limitedSubscriptionDiscount = firstAmountDiscount + nextAmountDiscount * (paymentsCount - 1)
        }

        limitedSubscriptionPriceWithCoupon = showNewPricingPlanContent
          ? convertToPrice(totalAmount - limitedSubscriptionDiscount, currency)
          : convertToPrice(calculatePriceWithCoupon(displayPrice, coupon.data.value, coupon.data.percents), currency)
      } else {
        const discountPrice = calculatePriceWithCoupon(displayPrice, coupon.data.value, coupon.data.percents)
        const limitedSubscriptionDiscount = Number(displayPrice) - discountPrice
        limitedSubscriptionPriceWithCoupon = showNewPricingPlanContent
          ? convertToPrice(totalAmount - limitedSubscriptionDiscount, currency)
          : convertToPrice(discountPrice, currency)
      }
    }

    priceToShow = limitedSubscriptionPriceWithCoupon || limitedSubscriptionPrice
    oldPriceToShow = showCouponData && limitedSubscriptionPriceWithCoupon ? limitedSubscriptionPrice : null
    priceSupportiveText = I18n.t('react.shared.pricing_plan.over_payments', { paymentsCount })
    modalPaymentItems = getPaymentItems()
  } else {
    priceToShow = showCouponData
      ? convertToPrice(calculatePriceWithCoupon(displayPrice, coupon.data.value, coupon.data.percents), currency)
      : formattedPrice
    oldPriceToShow = showCouponData ? formattedPrice : formattedOldPrice
  }

  return (
    <EloCard
      elevation='1'
      className='payment-plan elo-card--without-min-width'
      contentClassName='elo-card__content--without-margin'
      selected={checked && !!forForm}
      selectedBorderColor={paymentPageColor}
      handleSelect={() => !radioButtonDisabled && onSelectPlan && id && onSelectPlan(id)}
    >
      <div
        className={classNames('payment-plan__new-container', {
          'payment-plan__new-container--in-form': showEnablingPlans && enabledPlans,
        })}
      >
        <div className='preferred-plan-container'>
          <div className='payment-plan__new-container--selection'>
            {showEnablingPlans && id && enabledPlans && togglePlan && (
              <CheckboxField checked={enabledPlans[id]} onChange={() => togglePlan(id)} />
            )}
            <EloRadioButton
              checked={checked && !!forForm}
              disabled={radioButtonDisabled}
              onChange={() => onSelectPlan && id && onSelectPlan(id)}
              hidden={!forForm && !keepEnabled}
              borderColor={paymentPageColor}
              size='extra-small'
            >
              {pricingPlanName}
            </EloRadioButton>
            {showCouponData && (
              <EloStatusBadge
                highlight={false}
                badgeColor={COUPON_COLOR}
                icon={<EloCheckCircleIcon />}
                className='payment-plan__new-container--coupon-applied'
              >
                <span>
                  {`${
                    coupon.data.percents
                      ? convertToPercents(Number(coupon.data.value))
                      : convertToPrice(coupon.data.value, currency)
                  } ${I18n.t('react.shared.off')}`}
                </span>
              </EloStatusBadge>
            )}
          </div>
          {displayRecommendedBadge && (
            <div className='preferred-plan-container__badge' data-testid='recommended-badge'>
              <EloStatusBadge
                icon={<EloStarUnselectedIcon />}
                status='success'
                label={I18n.t('react.shared.pricing_plan.recommended')}
                backgroundColor={paymentRecommendedBadgeColor}
                badgeColor={paymentRecommendedBadgeTextColor}
              />
            </div>
          )}
        </div>
        {priceToShow && (
          <div className='plan-item-price' translate='no'>
            <EloPriceDisplay
              price={priceToShow}
              oldPrice={oldPriceToShow}
              netPrice={netText}
              supportiveText={showNewPricingPlanContent ? priceSupportiveText : ''}
            />
          </div>
        )}
      </div>

      {showNewPricingPlanContent ? (
        <>
          {getPricingPlanDescription({ description, pricingPlan }) ? (
            <div className='description-info' data-testid='pricing-plan-description'>
              {getPricingPlanDescription({ description, pricingPlan })}
            </div>
          ) : (
            <>
              {isFormOneTime && showTestPeriod && testPeriodEnabled && (
                <div className='description-info' data-testid='pricing-plan-description-test-period'>
                  <EloPaymentPlan
                    paymentItems={[
                      {
                        variant: 'trial',
                        paymentName: I18n.t('react.shared.pricing_plan.try_for_free', { count: testPeriod }),
                        price: convertToPrice(0, currency),
                        thinItem: true,
                      },
                      {
                        variant: 'default',
                        paymentName: pricingPlanName,
                        price: priceToShow,
                        oldPrice: oldPriceToShow,
                        netPrice: netText,
                        on: formattedFirstIntervalDate,
                        onLabel: I18n.t('react.shared.on'),
                      },
                    ]}
                    paymentColor={paymentPageColor}
                  />
                </div>
              )}

              {!isFormOneTime && (
                <div className='description-info' data-testid='pricing-plan-description-test-period-and-subscription'>
                  {(isInstallment || isLimitedSubscription) && (
                    <>
                      {isLimitedSubscription && (
                        <>
                          {minTerm && minimumTermInfo}
                          {minTerm && <br />}
                        </>
                      )}
                      <EloPaymentPlan
                        paymentItems={getPaymentItems()}
                        paymentColor={paymentPageColor}
                        showFullPaymentPlanModal={showFullPaymentPlanModal}
                        toggleFullPaymentPlanModal={toggleFullPaymentPlanModal}
                      />
                    </>
                  )}
                  {isSubscription && (
                    <>
                      {minTerm && minimumTermInfo}
                      {minTerm && <br />}
                      <EloPaymentPlan
                        paymentItems={getSubscriptionPaymentItems()}
                        paymentColor={paymentPageColor}
                        showFullPaymentPlanModal={showFullPaymentPlanModal}
                        toggleFullPaymentPlanModal={toggleFullPaymentPlanModal}
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {getPricingPlanDescription({ description, pricingPlan }) ? (
            <div className='details' data-testid='pricing-plan-description'>
              {getPricingPlanDescription({ description, pricingPlan })}
            </div>
          ) : (
            <>
              {isFormOneTime && showTestPeriod && testPeriodEnabled && (
                <div className='details' data-testid='pricing-plan-description-test-period'>
                  {I18n.t('react.shared.pricing_plan.test_period', { test_period: testPeriod })}
                </div>
              )}

              {!isFormOneTime && (
                <div className='details' data-testid='pricing-plan-description-test-period-and-subscription'>
                  {showTestPeriod &&
                    testPeriodEnabled &&
                    I18n.t('react.shared.pricing_plan.test_period', { test_period: testPeriod })}
                  <span
                    translate='no'
                    data-testid='first-charge-info'
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('react.shared.pricing_plan.first_charge_info', {
                        date:
                          absoluteDates && rate1Date
                            ? createFormattedDate(new Date(rate1Date))
                            : formattedFirstIntervalDate,
                        amount:
                          amountCrossed &&
                          amountCrossed({
                            amount: convertToPrice(displayPrice, currency) || '',
                            isCrossed: isCouponApplied,
                          }),
                      }),
                    }}
                  />
                  {customIntervals && minTerm && minimumTermInfo}
                  {!customIntervals && (
                    <>
                      &nbsp;
                      <span
                        translate='no'
                        dangerouslySetInnerHTML={{
                          __html: I18n.t(`react.shared.pricing_plan.next_charges_info.${form}`, {
                            date: nextIntervalDate || NO_DATA,
                            interval: I18n.t(`react.shared.pricing_plan.interval.${nextInterval}`),
                            charges: parseInt(String(paymentsCount), 10) - 1,
                            amount:
                              amountCrossed({
                                amount: nextAmountText || '',
                                isCrossed: isCouponApplied && isRecurring,
                              }) || '',
                          }),
                        }}
                      />
                      {minTerm && minimumTermInfo}
                    </>
                  )}
                  {form && [PAYMENT_PLANS.limitedSubscription, PAYMENT_PLANS.installment].includes(form) && (
                    <span
                      translate='no'
                      dangerouslySetInnerHTML={{
                        __html: I18n.t('react.shared.pricing_plan.total_splited', {
                          charges: paymentsCount,
                          total:
                            amountCrossed({
                              amount: totalPrice || '',
                              isCrossed: isCouponApplied && isRecurring,
                            }) || '',
                        }),
                      }}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
      {isShopProfile && activateCountdown && (
        <Countdown
          paymentCountdownBgColor={paymentCountdownBgColor}
          paymentCountdownBorderColor={paymentCountdownBorderColor}
          paymentCountdownTextColor={paymentCountdownTextColor}
          paymentCountdownTimeColor={paymentCountdownTimeColor}
          countdownText={countdownText}
          validTill={validTill}
        />
      )}

      {showFullPaymentPlanModal && (
        <EloModal isOpen onClose={() => toggleFullPaymentPlanModal()} size='large' className='payment-plan-modal'>
          <EloModalHeader>
            <div className='payment-plan-modal__header'>
              <div>
                {I18n.t('react.shared.pricing_plan.payment_plan')}: {pricingPlanName}
              </div>

              {showCouponData && (
                <EloStatusBadge
                  highlight={false}
                  badgeColor={COUPON_COLOR}
                  icon={<EloCheckCircleIcon />}
                  className='payment-plan__new-container--coupon-applied'
                >
                  <span>
                    {`${
                      coupon.data.percents
                        ? convertToPercents(Number(coupon.data.value))
                        : convertToPrice(coupon.data.value, currency)
                    } ${I18n.t('react.shared.off')}`}
                  </span>
                </EloStatusBadge>
              )}
            </div>
          </EloModalHeader>
          <EloModalBody>
            {(isInstallment || isLimitedSubscription) && (
              <div className='payment-plan-modal__price'>
                <div>{I18n.t('react.shared.payment.total_amount')}:</div>
                <EloPriceDisplay size='large' price={priceToShow} oldPrice={oldPriceToShow} netPrice={netText} />
              </div>
            )}
            <EloPaymentPlan
              showFullPaymentPlanModal={showFullPaymentPlanModal}
              paymentItems={modalPaymentItems}
              paymentColor={paymentPageColor}
            />
          </EloModalBody>
          <EloModalFooter className='elo-ui-modal__footer--end'>
            <EloButton variant='secondary' onClick={() => toggleFullPaymentPlanModal()}>
              {I18n.t('react.shared.button.close')}
            </EloButton>
          </EloModalFooter>
        </EloModal>
      )}
    </EloCard>
  )
})

export const PricingPlanListItem: React.FC<PricingPlanListItemProps> = (props) => {
  const {
    pricingPlan = {},
    coupon,
    forForm,
    keepEnabled,
    checked,
    onSelectPlan,
    enabledPlans,
    togglePlan,
    showEnablingPlans = false,
    ticketId,
    minTerm,
    convertToPrice = () => '',
    amountCrossed = () => '',
    paymentPageColor,
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
    withoutNet,
    paymentRecommendedBadgeColor,
    paymentRecommendedBadgeTextColor,
    layout,
    numberOfPlans,
    showNewPricingPlanContent,
  } = props
  const { firstIntervalDate, prefs = {} } = pricingPlan
  const { customStartDay, minimumTermInitialPeriod, minimumTermNextPeriod } = prefs

  const formattedCustomStartDate = Number(customStartDay) > 9 ? customStartDay : `0${customStartDay}`
  const formattedFirstIntervalDate =
    firstIntervalDate && !!Number(customStartDay)
      ? firstIntervalDate?.replace(VALUE_BEFORE_FIRST_DOT_REGEX, formattedCustomStartDate)
      : firstIntervalDate || NO_DATA

  return (
    <NewPricingPlanContent
      checked={checked}
      forForm={forForm}
      paymentPageColor={paymentPageColor}
      onSelectPlan={onSelectPlan}
      pricingPlan={pricingPlan}
      showEnablingPlans={showEnablingPlans}
      enabledPlans={enabledPlans}
      togglePlan={togglePlan}
      keepEnabled={keepEnabled}
      paymentRecommendedBadgeColor={paymentRecommendedBadgeColor}
      paymentRecommendedBadgeTextColor={paymentRecommendedBadgeTextColor}
      convertToPrice={convertToPrice}
      withoutNet={withoutNet}
      layout={layout}
      numberOfPlans={numberOfPlans}
      formattedFirstIntervalDate={formattedFirstIntervalDate}
      minTerm={minTerm}
      minimumTermInitialPeriod={minimumTermInitialPeriod}
      minimumTermNextPeriod={minimumTermNextPeriod}
      paymentCountdownBgColor={paymentCountdownBgColor}
      paymentCountdownBorderColor={paymentCountdownBorderColor}
      paymentCountdownTextColor={paymentCountdownTextColor}
      paymentCountdownTimeColor={paymentCountdownTimeColor}
      countdownText={countdownText}
      coupon={coupon}
      ticketId={ticketId}
      amountCrossed={amountCrossed}
      showNewPricingPlanContent={showNewPricingPlanContent}
    />
  )
}

PricingPlanListItem.displayName = 'PricingPlanListItem'

interface Form {
  setFieldValue: (name: string, planId: number) => void
}

interface Field {
  name?: string
}

interface PricingPlansListProps {
  field?: Field
  form?: Form
  onSelectPlan?: () => void
  enabledPlans?: Record<string, boolean>
  togglePlan?: () => void
  showEnablingPlans?: boolean
  pricingPlans?: PricingPlan[]
  coupon?: Coupon
  hideTitle?: boolean
  selectedId?: number | string
  forForm?: boolean
  keepEnabled?: boolean
  ticketId?: number
  convertToPrice?: (
    value: string | number | undefined,
    currencyId?: number | string,
    separator?: string
  ) => string | number // currencyStore.convertToPrice
  amountCrossed?: (object: AmountCrossedArg) => string | number // helpers.utils
  isWindows?: boolean // browser.utils
  ref?: object
  withoutNet?: boolean
  themeStore?: any
  paymentPageColor?: string
  layout?: string
  paymentRecommendedBadgeColor?: string
  paymentRecommendedBadgeTextColor?: string
  paymentCountdownBgColor?: string
  paymentCountdownBorderColor?: string
  paymentCountdownTextColor?: string
  paymentCountdownTimeColor?: string
  countdownText?: string
  showNewPricingPlanContent?: boolean
}

export const PricingPlansList = (props: PricingPlansListProps) => {
  const formikOnSelectPlan = (planId: number): void => {
    const { field, form } = props
    form?.setFieldValue(String(field?.name), planId)
  }

  const {
    onSelectPlan,
    enabledPlans,
    togglePlan,
    showEnablingPlans,
    pricingPlans = [],
    coupon,
    hideTitle = false,
    selectedId,
    forForm,
    keepEnabled,
    ticketId,
    convertToPrice,
    amountCrossed,
    isWindows,
    withoutNet,
    paymentPageColor,
    paymentRecommendedBadgeColor,
    paymentRecommendedBadgeTextColor,
    layout,
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
    showNewPricingPlanContent = false,
  } = props

  return (
    <div className='payment-plans'>
      <>
        {!hideTitle && <div className='payment-plans-title'>{I18n.t('react.shared.pricing_plan.title')}</div>}
        {pricingPlans &&
          pricingPlans.map((plan, index) => (
            <Fragment key={plan.id ? `${plan.id}_${index}` : uuid()}>
              {index !== 0 && <div className='plan-wrapper' />}
              <PricingPlanListItem
                pricingPlan={plan}
                checked={String(plan.id) === String(selectedId)}
                forForm={forForm}
                keepEnabled={keepEnabled}
                coupon={coupon}
                ticketId={ticketId}
                onSelectPlan={onSelectPlan || formikOnSelectPlan}
                enabledPlans={enabledPlans}
                togglePlan={togglePlan}
                showEnablingPlans={showEnablingPlans}
                minTerm={plan?.prefs?.minimumTerms}
                withoutNet={withoutNet}
                numberOfPlans={pricingPlans?.length}
                showNewPricingPlanContent={showNewPricingPlanContent}
                {...{
                  convertToPrice,
                  amountCrossed,
                  isWindows,
                  paymentPageColor,
                  paymentRecommendedBadgeColor,
                  paymentRecommendedBadgeTextColor,
                  layout,
                  paymentCountdownBgColor,
                  paymentCountdownBorderColor,
                  paymentCountdownTextColor,
                  paymentCountdownTimeColor,
                  countdownText,
                }}
              />
            </Fragment>
          ))}
      </>
    </div>
  )
}

PricingPlansList.displayName = 'PricingPlansList'
